import logo from "./logo.svg";
import "./App.css";
import { CatalogueProvider } from "./providers/catalogue";
import { CartProvider } from "./providers/cart";
import ProductList from "./components/productLIst";

function App() {
  return (
    <div className="App">
      <CatalogueProvider>
        <CartProvider>
          <ProductList type='catalogue'/>
          <ProductList type='cart'/>
        </CartProvider>
      </CatalogueProvider>
    </div>
  );
}

export default App;
